<template>
  <v-container class="pt-md-5">
    <v-row>
      <v-col v-for="(stat, i) in stats" :key="i" cols="12" xs="12" sm="12" md="4" lg="4">
        <v-card class="mx-auto" elevation="17">
          <v-list-item three-line>
            <v-list-item-avatar tile size="80" :color="stat.color" class="rounded">
              <v-icon size="40"> {{ stat.icon }} </v-icon>
            </v-list-item-avatar>

            <v-list-item-content class="text-right">
              <div>
                {{ stat.title }}
                <span v-if="stat.provinces.length > 0" class="red--text">{{ provinceSelected || 'Banten' }}</span>
                <v-btn
                  v-if="stat.provinces.length > 0"
                  text
                  small
                  icon
                  class="ml-1"
                  @click.native="dialogSelectProvince = true"
                >
                  <v-icon x-small color="grey"> mdi-pencil </v-icon>
                </v-btn>
              </div>
              <v-list-item-title class="text-h3">
                {{ stat.value }}
                <sup
                  v-if="stat.increment > 0"
                  class="green--text"
                  :title="`${stat.title} hari ini bertambah ${stat.increment}`"
                >
                  +{{ stat.increment }}
                </sup>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider />

          <v-card-actions class="text-caption">
            {{ stat.actionText }}
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12">
        <molecules-increment-chart />
      </v-col>
    </v-row>

    <!-- <v-row v-if="!$areIncludes($store.state.user.roles, [$roles.Worker, $roles.Freelance])">
      <v-col v-for="(stat, i) in stats" :key="i" cols="12" sm="12" md="6" lg="4">
        <molecules-material-stat-card v-bind="stat">
          {{ attrs }}
          <template #actions>
            <v-icon class="mr-2" x-small v-text="actionIcon" />
            <div class="text-truncate">{{ actionText }}</div>
          </template>
        </molecules-material-stat-card>
      </v-col>
      <v-col cols="12">
        <molecules-increment-chart />
      </v-col>
    </v-row>
    <div v-else>
      <molecules-card
        :plainDisabled="true"
        buttonText="Buat Artikel Baru"
        buttonClass="primary--text primary lighten-5"
        to="/blog"
      >
        <v-card-title class="d-flex flex-column">
          <atoms-image :width="250" src="./assets/receipt.svg" class="my-8" />
          <atoms-title :h3="true" class="mt-4">Buat Artikel</atoms-title>
        </v-card-title>
        <v-card-subtitle>
          <atoms-text>Terhitung sebanyak {{ $store.state.article.cout || 0 }} artikel telah Anda buat</atoms-text>
        </v-card-subtitle>
      </molecules-card>
    </div> -->

    <v-dialog v-model="dialogSelectProvince" max-width="300">
      <v-card>
        <v-card-title class="headline">Pilih Provinsi</v-card-title>
        <v-divider />

        <v-card-text>
          <v-autocomplete
            v-model="provinceSelected"
            :items="provinces"
            label="Provinsi"
            hide-details
            dense
            outlined
            class="mt-5"
          ></v-autocomplete>
        </v-card-text>

        <v-divider />
        <v-card-actions class="justify-end">
          <v-btn text color="primary" @click="dialogSelectProvince = false">Tampilkan data</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// Utilities
export default {
  name: 'DashboardView',
  data: () => ({
    stats: [
      {
        actionIcon: 'mdi-tag',
        actionText: 'Source from Database HK',
        color: 'primary',
        icon: 'mdi-account-multiple-outline',
        title: 'Pekerja',
        provinces: [],
        value: sessionStorage.getItem('totalWorkers') || 'Loading...',
        increment: 0,
      },
      {
        actionIcon: 'mdi-tag',
        actionText: 'Source from Database HK',
        color: 'success',
        icon: 'mdi-store',
        title: 'Klien',
        provinces: [],
        value: sessionStorage.getItem('totalClients') || 'Loading...',
        increment: 0,
      },
      {
        actionIcon: 'mdi-tag',
        actionText: 'Source from Database HK',
        color: 'warning',
        icon: 'mdi-flag',
        title: 'Provinsi',
        provinces: ['DKI Jakarta', 'Jawa Barat', 'Banten'],
        value: sessionStorage.getItem('totalWorkerByProvince') || 'Loading...',
        increment: 0,
      },
    ],
    provinces: [
      'Aceh',
      'Sumatera Utara',
      'Sumatera Barat',
      'Riau',
      'Jambi',
      'Sumatera Selatan',
      'Bengkulu',
      'Lampung',
      'Kepulauan Bangka Belitung',
      'Kepulauan Riau',
      'DKI Jakarta',
      'Jawa Barat',
      'Jawa Tengah',
      'DI Yogyakarta',
      'Jawa Timur',
      'Banten',
      'Bali',
      'Nusa Tenggara Barat',
      'Nusa Tenggara Timur',
      'Kalimantan Barat',
      'Kalimantan Tengah',
      'Kalimantan Selatan',
      'Kalimantan Timur',
      'Kalimantan Utara',
      'Sulawesi Utara',
      'Sulawesi Tengah',
      'Sulawesi Selatan',
      'Sulawesi Tenggara',
      'Gorontalo',
      'Sulawesi Barat',
      'Maluku',
      'Maluku Utara',
      'Papua Barat',
      'Papua',
    ],
    provinceSelected: null,
    dialogSelectProvince: false,
  }),

  async beforeCreate() {
    try {
      // worker
      await this.$store.dispatch('article/getSummary')
      const workerQuery = {
        active: true,
        approved: true,
        resigned: { $ne: true },
        blacklist: { $ne: true },
      }
      const data = await this.$api.get(`Workers/count`, {
        params: {
          jsonQuery: JSON.stringify(workerQuery),
        },
      })
      const workerCount = data?.result || 0
      const dataIncrement = await this.$api.get(`Workers/count`, {
        params: {
          jsonQuery: JSON.stringify({
            ...workerQuery,
            _createdDate: {
              $gte: new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(7, 0, 0, 0)), // kemarin
              $lte: new Date(new Date().setHours(-1, 59, 59, 59)), // hari kemarin
            },
          }),
        },
      })
      const workerIncrement = dataIncrement?.result || 0
      const totalWorkers = new Intl.NumberFormat(['ban', 'id']).format(workerCount)
      sessionStorage.setItem('totalWorkers', totalWorkers)
      this.stats[0].value = totalWorkers
      this.stats[0].increment = workerIncrement
      this.stats[0].actionText = `Jumlah pekerja yang terdaftar ada ${totalWorkers} orang. dan bertambah hari ini ${workerIncrement} orang.`

      // client
      const dataClient = await this.$api.get(`Clients/count`)
      const clientCount = dataClient?.result || 0
      const dataClientIncrement = await this.$api.get(`Clients/count`, {
        params: {
          jsonQuery: JSON.stringify({
            _createdDate: {
              $gte: new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(7, 0, 0, 0)), // kemarin
              $lte: new Date(new Date().setHours(-1, 59, 59, 59)), // hari kemarin
            },
          }),
        },
      })
      const clientIncrement = dataClientIncrement?.result || 0
      const totalClients = new Intl.NumberFormat(['ban', 'id']).format(clientCount)
      sessionStorage.setItem('totalClients', totalClients)
      this.stats[1].value = totalClients
      this.stats[1].increment = clientIncrement
      this.stats[1].actionText = `Jumlah klien yang terdaftar ada ${totalClients} orang. dan bertambah hari ini ${clientIncrement} orang.`

      // worker by province
      const workerQueryByProvince = {
        active: true,
        approved: true,
        resigned: { $ne: true },
        blacklist: { $ne: true },
        'address.province': this.provinceSelected || 'Banten',
      }
      const countWorkerByProvince = await this.$api.get(`Workers/count`, {
        params: {
          jsonQuery: JSON.stringify(workerQueryByProvince),
        },
      })
      const dataWorkerByProvinceIncrement = await this.$api.get(`Workers/count`, {
        params: {
          jsonQuery: JSON.stringify({
            ...workerQueryByProvince,
            _createdDate: {
              $gte: new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(7, 0, 0, 0)), // kemarin
              $lte: new Date(new Date().setHours(-1, 59, 59, 59)), // hari kemarin
            },
          }),
        },
      })
      const totalWorkerByProvince = new Intl.NumberFormat(['ban', 'id']).format(countWorkerByProvince?.result || 0)
      const workerByProvinceIncrement = dataWorkerByProvinceIncrement?.result || 0
      sessionStorage.setItem('totalWorkerByProvince', totalWorkerByProvince)
      this.stats[2].value = totalWorkerByProvince
      this.stats[2].increment = workerByProvinceIncrement
      this.stats[2].actionText = `Jumlah pekerja yang terdaftar di provinsi ${
        this.provinceSelected || 'BANTEN'
      } ada ${totalWorkerByProvince} orang. dan bertambah hari ini ${workerByProvinceIncrement} orang.`
    } catch (err) {
      this.$showDialog({
        title: 'Terdapat Kendala',
        body: err?.response?.data?.message || err?.message,
      })
    }
  },

  watch: {
    async provinceSelected(val) {
      try {
        const workerQueryByProvince = {
          active: true,
          approved: true,
          resigned: { $ne: true },
          blacklist: { $ne: true },
          'address.province': val || 'Banten',
        }
        const countWorkerByProvince = await this.$api.get(`Workers/count`, {
          params: {
            jsonQuery: JSON.stringify(workerQueryByProvince),
          },
        })
        const dataWorkerByProvinceIncrement = await this.$api.get(`Workers/count`, {
          params: {
            jsonQuery: JSON.stringify({
              ...workerQueryByProvince,
              _createdDate: {
                $gte: new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(7, 0, 0, 0)), // kemarin
                $lte: new Date(new Date().setHours(-1, 59, 59, 59)), // hari kemarin
              },
            }),
          },
        })
        const totalWorkerByProvince = new Intl.NumberFormat(['ban', 'id']).format(countWorkerByProvince?.result || 0)
        const workerByProvinceIncrement = dataWorkerByProvinceIncrement?.result || 0
        sessionStorage.setItem('totalWorkerByProvince', totalWorkerByProvince)
        this.stats[2].value = totalWorkerByProvince
        this.stats[2].increment = workerByProvinceIncrement
        this.stats[2].actionText = `Jumlah pekerja yang terdaftar di provinsi ${
          val || 'BANTEN'
        } ada ${totalWorkerByProvince} orang. dan bertambah hari ini ${workerByProvinceIncrement} orang.`
      } catch (err) {
        this.$showDialog({
          title: 'Terdapat Kendala',
          body: err?.response?.data?.message || err?.message,
        })
      }
    },
  },
}
</script>
